section {
	display: flex;
	flex-direction: column;
	gap: 12px;
	// max-width: 75%;
	margin: auto;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	width: 100%;
}

.roadmap {
	min-height: calc(100vh - 64px);
	padding-block-start: 12rem;

	@media (max-width: 768px) {
		padding-block-start: 8rem;
	}

	hr {
		width: 100%;
		border: 1px solid var(--ss-primary);
	}

	.title {
		font-size: 42px;
		font-weight: 600;
		// border-bottom: 2px solid var(--ss-primary);
		padding: 5px;
		margin-block-end: 2rem;
	}
	.content {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-block-end: 2rem;
		line-height: 1.5;

		h2 {
			font-size: 24px;
		}

		.tech {
			.item {

				.header {
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					.name {
						display: flex;
						flex-direction: row;
						gap: 8px;
						place-items: center;

						> h3 {
							// margin-block-end: .75rem;
							font-size: 24px;
						}
						
						img {
							width: 32px;
							height: 32px;
							border-radius: 50%;
						}
					}

					.socials {
						a {
							color: white;
							text-decoration: none;
							padding: 8px;
							border-radius: 8px;
							background-color: var(--ss-accent);
							display: flex;
							place-content: center;
							place-items: center;
							gap: 8px;
							transition: ease-in-out 200ms all;

							&:hover {
								background-color: var(--ss-accent-hover);
							}
						}
					}
				}

				.layout {
					margin-block-end: 1.5em;
					padding-block: 1rem;
					border-bottom: 1px solid var(--ss-primary);
					.list {
						.question {
							margin-block-end: 1.5rem;
							> h5 {
								font-size: 18px;
								font-weight: 600;
								margin-block-end: .5rem;
							}

							p {

							}
						}
					}
					.stack {
						display: flex;
						list-style-type: none;
						flex-direction: row;
						flex-wrap: wrap;
						gap: 1rem;
						padding: unset;

						li {
							padding: 8px 16px;
							border-radius: 8px;
							background-color: var(--ss-primary);
							color: white;
						}
					}
				}
			}
		}

		.grid {
			display: flex;
			flex-direction: column;
			gap: 40px;
			width: 100%;
	
			.item {
				position: relative;
				width: 100%;
				height: 128px;
				border-radius: 8px;
				border: 2px solid var(--ss-primary);
				background-image: url("../assets/donate_banner.jpg");
				// overflow: hidden;
				// animation: pulseShadow 2s infinite;
	
				.info {
					position: relative;
					z-index: 10;
					font-size: 24px;
					height: 100%;
					display: flex;
					flex-direction: column;
					place-content: center;
					padding: 0 20px;
	
					.goal {
						position: absolute;
						top: -16px;
						left: 20px;
						width: 100%;
						font-size: 32px;
						font-weight: bold;
	
					}
				}
	
				&:not(.reached) {
					opacity: .5;
					filter: grayscale(1);
				}
	
				&::after {
					content: "";
					display: block;
					width: 16px;
					height: 16px;
					position: absolute;
					top: 0;
					bottom: 0;
					left: -8px;
					margin: auto;
					background-color: var(--ss-primary);
					border-radius: 50%;
					z-index: 5;
				}
	
				.quarter {
	
				}
	
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					z-index: 1;
	
					&::after {
						content: "";
						display: block;
						width: 100%;
						height: 256px;
						position: absolute;
						top: 0;
						left: 0;
						background-color: black;
						z-index: 2;
					}
				}
			}
		}
	}
}

@keyframes pulseShadow {
	0% {
		box-shadow: 0 0 0 0 var(--ss-primary);
	}
	50% {
		box-shadow: 0 0 0 10px var(--ss-primary);
	}
	100% {
		box-shadow: 0 0 0 0 var(--ss-primary);
	}
}