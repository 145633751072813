.footer {
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;
	height: 64px;
	// background-image: linear-gradient(to top, #0000007a 50%, transparent 100%);
	z-index: 50;
	overflow-x: hidden;

	@media (max-width: 768px) {
		display: none;
	}

	.inner {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		max-width: 1280px;
		width: 100%;
		margin: auto;
		height: 100%;
		z-index: 40;

		@media (max-width: 768px) {
			flex-direction: column;
			padding: 10px 20px;
		}

		p {
			color: var(--ss-text_secondary);
		}
	
		.links {
			display: flex;
			flex-direction: row;
			gap: 20px;
	
			a {
				color: var(--ss-primary);
				text-decoration: none;
			}
		}
	}
}