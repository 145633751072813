section {
	display: flex;
	flex-direction: column;
	gap: 12px;
	max-width: 75%;
	margin: auto;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	width: 100%;

	@media (max-width: 768px) {
		max-width: 100%;
	}
}

.top {
	// align-items: center;
	// justify-content: center;
	min-height: 100vh;
	padding-block-start: 10%;
	position: relative;
	
	@media (max-width: 768px) {
		padding-block-start: calc(100% / 3);
	}

	.scroll_down {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 64px;
		width: fit-content;

		span {
			display: block;
			width: 14px;
			height: 14px;
			border-bottom: 2px solid white;
			border-right: 2px solid white;
			transform: rotate(45deg);
			margin: -10px;
			animation: animate 2s infinite;

			&:nth-child(2) {
				animation-delay: -0.15s;
			}

			&:nth-child(3) {
				animation-delay: -0.3s;
			}
		}
	}

	.cta {
		position: relative;

		.content {
			width: 58%;

			@media (max-width: 768px) {
				width: 100%;
			}

			h1 {
				font-size: 33px;
				@media (max-width: 768px) {
					font-size: 16px;
				}
				font-weight: 600;
				margin-block-end: 1rem;
				// text-align: center;
			}
		
			h4 {
				@media (max-width: 768px) {
					font-size: 12px;
				}
				font-size: 24px;
				font-weight: 400;
				// text-align: center;
				color: var(--ss-text_secondary);
			}
		}

		.logo {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto 0;
			width: 256px;
			z-index: -1;
			
			@media (max-width: 768px) {
				display: none;
			}
		}
	}


	.stats {
		// position: absolute;
		position: relative;
		top: 64px;
		left: 0;
		right: 0;
		// margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		margin-block-start: 64px;
		gap: 10px;
		
		// @media (max-width: 768px) {
			// 	display: none;
		// }

		@media (max-height: 1145px) {
			margin-block-start: 32px;
		}
		
		@media (max-width: 768px) {
			// .item:first-child {
			// 	display: none;
			// }
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
			place-items: center;
			place-content: center;
			margin-block-start: 32px;
			top: 32px;
		}

		
		.item {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			place-content: center;
			// background-color: var(--ss-accent);
			padding: 15px 25px;
			border-radius: 8px;
			// width: 100%;
			gap: 8px;
			height: 110px;
			
			.dots {
				background: radial-gradient(circle at center, #151515 25%, rgba(26, 26, 26, 0) 25%), rgba(0, 0, 0, 0);
				background-size: 1rem 1rem;
				pointer-events: none;
				position: absolute;
				// bottom: -2rem;
				height: 6rem;
				left: 0;
				// right: -3rem;
				width: 12rem;
				z-index: -1;
			}

			.number {
				font-size: 32px;
				@media (max-width: 768px) {
					font-size: 16px;
				}
				color: var(--ss-primary);
				text-align: left;
				font-weight: 800;
				background-image: linear-gradient(to right, var(--ss-primary) 0%, var(--ss-secondary) 100%);
            	-webkit-background-clip: text;
            	-webkit-text-fill-color: transparent;
			}
			
			.info {
				@media (max-width: 768px) {
					font-size: 12px;
				}
				font-size: 20px;
			}
		}
	}

	.buttons {
		position: relative;
		margin-block-start: 1rem;
		// display: flex;
		// flex-direction: row;
		// gap: 20px;

		@media (max-width: 768px) {
			text-align: center;
		}

		> label {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 32px;
			margin: auto;
			color: #7d7d7d;
			display: flex;
			place-items: center;
			place-content: center;

			@media screen and (max-width: 768px) {
				display: none;
			}
		}

		.input {
			border-radius: 8px;
			border: unset;
			padding: 15px 35px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

			@media (max-width: 768px) {
				padding: 15px 20px;
			}
		}
		
		> button {
			min-width: 96px;

			@media (max-width: 768px) {
				min-width: 64px;
			}
			
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.image {
		// position: absolute;
		display: flex;
		flex-direction: row;
		bottom: -64px;
		left: 0;
		right: 0;
		margin: auto;
		max-width: 1280px;
		align-items: center;
		place-content: center;
		z-index: -1;
		margin-block-start: 2rem;

		@media (max-width: 768px) {
			bottom: 0;
		}

		.leaderboard {
			width: 100%;
			transform: perspective(100px);
			filter: brightness(0.4);
			transition: filter 0.3s;
			mask-image: linear-gradient(to top, transparent 5%, black 75%);
	
			// &:hover {
			// 	filter: brightness(.65);
			// }
		}
	}
}

.text {
	display: block;
	position: absolute;
	z-index: 5;
	background: var(--ss-accent-hover);
	padding: 20px;
	border-radius: 8px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: fit-content;
	height: fit-content;

	h3 {
		font-size: 20px;
		font-weight: bold;
		margin-block-end: 6px;
	}
}

.search_engine {
	margin-block: 6rem;
	min-height: 50vh;

	h1 {
		@media (max-width: 768px) {
			font-size: 16px;
		}
		font-size: 32px;
		font-weight: 600;
		// text-align: center;
	}

	h4 {

		@media (max-width: 768px) {
			font-size: 12px;
		}
		font-size: 24px;
		font-weight: 400;
		// text-align: center;
		color: var(--ss-text_secondary);
	}

	.grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
		gap: 20px;

		.item {
			position: relative;
			padding: 20px;
			border-radius: 8px;
			// background-color: var(--ss-accent-hover);
			transition: background-color 0.3s;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			height: 220px;
			
			@media (max-width: 768px) {
				height: 220px;
			}

			.info {
				z-index: 10;
				text-align: center;

				h5 {
					font-size: 28px;
					font-weight: 600;
					margin-block-end: 6px;
					letter-spacing: 3px;
					
					@media (max-width: 768px) {
						letter-spacing: unset;
						font-size: 16px;
					}
				}

				p {
					@media (max-width: 768px) {
						letter-spacing: unset;
						font-size: 12px;
					}
				}
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				border-radius: 8px;
				margin-block-end: 20px;
				z-index: 0;
				filter: brightness(.4) blur(3px) grayscale(.5);
				opacity: .5;
				z-index: 5;
				height: 100%;
			}

			&:hover {
				background-color: var(--ss-accent);
			}
		}
	}
}

.profile {
	margin-block-start: 12rem;
	// text-align: right;
	// min-height: 100vh;

	h1 {
		font-size: 32px;
		font-weight: 600;

		@media (max-width: 768px) {
			font-size: 18px;
		}
		// text-align: center;
	}

	h4 {
		@media (max-width: 768px) {
			font-size: 14px;
		}

		font-size: 24px;
		font-weight: 400;
		// text-align: center;
		color: var(--ss-text_secondary);
	}

	.images {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		img {
			max-width: calc(960px / 2);

			@media (max-width: 768px) {
				max-width: 100%;
			}
		}
	}
}

.support_us {
	// margin-block-start: 4rem;
	// text-align: right;
	// min-height: 100vh;
	background-color: var(--ss-accent);
	border-radius: 8px;
	padding: 20px;

	h1 {
		font-size: 28px;
		font-weight: 600;

		@media (max-width: 768px) {
			font-size: 14px;
		}
		// text-align: center;
	}

	h4 {
		@media (max-width: 768px) {
			font-size: 10px;
		}

		font-size: 18px;
		font-weight: 400;
		// text-align: center;
		color: var(--ss-text_secondary);
	}

	.donator_tiers {
		.tier {

		}
	}
}

@keyframes animate {
	0% {
		opacity: 0;
		transform: rotate(45deg) translate(-14px, -14px);
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: rotate(45deg) translate(14px, 14px);
	}
}