.Button {
	outline: none;
	border: none;
	border-radius: 8px;
	padding: 15px 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	text-decoration: none;
	cursor: pointer;

	@media (max-width: 768px) {
		padding: 15px 20px;
		font-size: 12px;
	}

	&--Gradient {
		background-image: linear-gradient(90deg, var(unquote("--ss-primary")) 0%, var(unquote("--ss-secondary")) 100%);
		background-size: 200%;
		transition: background-position 0.5s;

		&:hover {
			background-position: right;
		}
	}

	&--Light {
		background-color: var(--ss-text);
		color: var(--ss-bg);
	}

	&--Skewed {
		transform: skew(-10deg);

		span {
			transform: skew(10deg);
		}
	}
}

.text-center {
	text-align: center;
}