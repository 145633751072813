@import "_reset.scss";
@import "_buttons.scss";
@import "_fonts.scss";

body {
	--ss-bg_lighter: #2B2C39;
    --ss-bg: #0A0712;
    --ss-bg_darkened: #08060f;
    --ss-bg_darkened--justalittle: #0e0f15;
    --ss-accent: #11121a;
    --ss-accent-hover: #14151f;
    --ss-body_bg: #131F37;
    --ss-text: #fff;
    --ss-text_secondary: #85858A;
    --ss-text_secondary-hover: #a6a6a8;
    --ss-primary: #7652c9;
    --ss-secondary: #fb5efd;
    --ss-success: #44bb88;
    --ss-danger: #ff4d4d;
    --ss-warning: #eca832;

	min-height: 100vh;
	min-width: 100vw;
	background-color: var(--ss-bg);
	color: var(--ss-text);
	overflow-x: hidden;
}

:root {
	font-family: "Inter", sans-serif;
	font-weight: 400;
}

@supports (font-variation-settings: normal) {
  :root { font-family: "InterVariable", sans-serif; font-optical-sizing: auto; }
}

#root {
	max-width: 1280px;
	margin: 0 auto;
	min-height: 100vh;

	main {
		@media (max-width: 768px) {
			padding-inline: 1.5rem;
		}
	}

	&::before {
		content: "";
		display: block;
		width: 512px;
		height: 100vh;
		opacity: .075;
		position: fixed;
		border-radius: 50%;
		top: 0;
		left: -256px;
		// left: 0;
		margin-block: auto;
		background-color: var(--ss-primary);
		filter: blur(128px);
	}

	&::after {
		content: "";
		display: block;
		width: 512px;
		height: 100vh;
		opacity: .05;
		position: fixed;
		border-radius: 50%;
		top: 0;
		bottom: 0;
		right: -256px;
		// margin-block: auto;
		background-color: var(--ss-secondary);
		filter: blur(128px);
	}
}

.Flex {
	display: flex;
	align-items: center;

	&--Row {
		flex-direction: row;
	}

	&--Column {
		flex-direction: column;
	}
}

