.navigation {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	height: 64px;
	padding: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-width: 1280px;
	margin: auto;
	z-index: 50;
	padding-inline: 12px;

	@media (max-width: 768px) {
		background-color: var(--ss-bg);
	}

	.mobile_nav {
		display: flex;
		position: absolute;
		top: 64px;
		left: 0;
		width: 100%;
		background-color: var(--ss-bg);
		max-height: 0;
		overflow: hidden;
		transition: max-height 100ms ease-in-out;
		z-index: -1;

		ul {
			width: 100%;
			list-style-type: none;
			
			li {
				padding: 8px 0;

				a {
					text-decoration: none;
				}
			}
		}
		
		@media (max-width: 768px) {
			&[data-open="true"] {
				max-height: fit-content;
				padding: 0 0 20px 0;
			}
		}
	}


	.hamburger {
		display: none;
	
		@media (max-width: 768px) {
			display: block;
		}
	}

	.brand {
		
		a {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;
			text-decoration: none;
			color: #fff;
		}

		h1 {
			@media (max-width: 768px) {
				display: none;
			}

			font-size: 24px;
			font-weight: 600;
			
			span {
				background-image: linear-gradient(to right, var(--ss-primary) 00%, var(--ss-secondary) 100%);
				-webkit-background-clip: text;
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
		
	}

	.items {
		display: flex;
		flex-direction: row;
		place-items: center;
		
		.socials {
			display: flex;
			flex-direction: row;
			list-style-type: none;
			gap: 10px;
			padding-inline: 1rem;
			place-content: center;
			place-items: center;

			li {
				list-style: none;
				// padding: 4px;
				// border-radius: 5px;
				// background-color: var(--ss-text);
				
				.discord {
					// fill: #7752ca;
					// fill: #5865F2;
					// width: 20px;
					// height: 20px;
					&:hover {
						fill: #7289DA;
					}
				}
				
				.steam {
					&:hover {
						fill: #72b2da;
					}
				}
				
				svg {
					width: 16px;
					height: 16px;
					fill: var(--ss-text_secondary)
					// fill: black;
				}
			}
		}

		@media (max-width: 768px) {
			display: none;
		}

		.divider {
			height: 16px;
			width: 1px;
			background-color: grey;
		}

		ul:first-child {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			list-style: none;

			li {
				margin: 0 10px;

				a {
					position: relative;
					text-decoration: none;
					color: var(--ss-text_secondary);
					transition: color 0.2s;

					&:hover {
						color: var(--ss-text_secondary-hover);
					}

					&.active {
						color: var(--ss-text);

						&::after {
							content: "";
							display: block;
							width: 50%;
							border-radius: 5px;
							margin: 0 auto;
							height: 3px;
							background-color: var(--ss-text);
							position: absolute;
							bottom: -5px;
							left: 0;
							right: 0;
						}
					}
				}
			}
		}
	}

	.cta {
		@media (max-width: 768px) {
			display: none;
		}
		> button {
			padding: 15px 20px;
			font-weight: 600;

		}
	}
}